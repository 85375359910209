import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Footer1 from '../components/Footer';
import Header1 from '../components/Header';
import PageBanner from '../components/PageBanner';
import ServicesCard from '../components/Services/index';

function Services() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={`${rpdata?.gallery?.[12]}`} currentPage="Reviews" heading="Reviews" />
            <h2 className="text-center pt-50">Our Reviews</h2>
            <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            <div class="elfsight-app-11408cd8-c447-4587-9291-51474fe5d827"></div>
            <Footer1 />
        </>
    );
}

export default Services;